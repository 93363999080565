import Vue from 'vue'
import Router from 'vue-router'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _bc869ba8 = () => interopDefault(import('../pages/404.vue' /* webpackChunkName: "pages/404" */))
const _7a3d17a5 = () => interopDefault(import('../pages/accounts/index.vue' /* webpackChunkName: "pages/accounts/index" */))
const _54dfb836 = () => interopDefault(import('../pages/chrome.vue' /* webpackChunkName: "pages/chrome" */))
const _01784360 = () => interopDefault(import('../pages/iframe.vue' /* webpackChunkName: "pages/iframe" */))
const _c84044c0 = () => interopDefault(import('../pages/login/index.vue' /* webpackChunkName: "pages/login/index" */))
const _c683fd02 = () => interopDefault(import('../pages/users/index.vue' /* webpackChunkName: "pages/users/index" */))
const _019ad5b9 = () => interopDefault(import('../pages/accounts/admin-calendar.vue' /* webpackChunkName: "pages/accounts/admin-calendar" */))
const _2707bc6c = () => interopDefault(import('../pages/accounts/admin-dashboard/index.vue' /* webpackChunkName: "pages/accounts/admin-dashboard/index" */))
const _05447f5b = () => interopDefault(import('../pages/accounts/onboarding/index.vue' /* webpackChunkName: "pages/accounts/onboarding/index" */))
const _ae2e99ac = () => interopDefault(import('../pages/global/amenities/index.vue' /* webpackChunkName: "pages/global/amenities/index" */))
const _58c81767 = () => interopDefault(import('../pages/global/changelog/index.vue' /* webpackChunkName: "pages/global/changelog/index" */))
const _a9b24f10 = () => interopDefault(import('../pages/global/charts/index.vue' /* webpackChunkName: "pages/global/charts/index" */))
const _425dfa92 = () => interopDefault(import('../pages/global/client-value/index.vue' /* webpackChunkName: "pages/global/client-value/index" */))
const _acc77306 = () => interopDefault(import('../pages/global/clubhouse/index.vue' /* webpackChunkName: "pages/global/clubhouse/index" */))
const _7dee3638 = () => interopDefault(import('../pages/global/component-breakdown/index.vue' /* webpackChunkName: "pages/global/component-breakdown/index" */))
const _c244043e = () => interopDefault(import('../pages/global/email-reports/index.vue' /* webpackChunkName: "pages/global/email-reports/index" */))
const _cbe7b740 = () => interopDefault(import('../pages/global/features/index.vue' /* webpackChunkName: "pages/global/features/index" */))
const _2e95d175 = () => interopDefault(import('../pages/global/form-builder/index.vue' /* webpackChunkName: "pages/global/form-builder/index" */))
const _b8e8300e = () => interopDefault(import('../pages/global/page-search/index.vue' /* webpackChunkName: "pages/global/page-search/index" */))
const _5fad2cb9 = () => interopDefault(import('../pages/global/queries/index.vue' /* webpackChunkName: "pages/global/queries/index" */))
const _1155e9ad = () => interopDefault(import('../pages/global/themes/index.vue' /* webpackChunkName: "pages/global/themes/index" */))
const _434d8914 = () => interopDefault(import('../pages/login/confirm-sign-in.vue' /* webpackChunkName: "pages/login/confirm-sign-in" */))
const _0c4209d3 = () => interopDefault(import('../pages/login/forgot-password.vue' /* webpackChunkName: "pages/login/forgot-password" */))
const _8ca2c69c = () => interopDefault(import('../pages/login/index-copy.vue' /* webpackChunkName: "pages/login/index-copy" */))
const _105be684 = () => interopDefault(import('../pages/my-account/notification-settings.vue' /* webpackChunkName: "pages/my-account/notification-settings" */))
const _68ddda57 = () => interopDefault(import('../pages/my-account/security.vue' /* webpackChunkName: "pages/my-account/security" */))
const _44777f1e = () => interopDefault(import('../pages/users/permissions.vue' /* webpackChunkName: "pages/users/permissions" */))
const _e3769ea0 = () => interopDefault(import('../pages/accounts/admin-dashboard/admin-dashboard.vue' /* webpackChunkName: "pages/accounts/admin-dashboard/admin-dashboard" */))
const _d420a0c6 = () => interopDefault(import('../pages/accounts/onboarding/dashboard.vue' /* webpackChunkName: "pages/accounts/onboarding/dashboard" */))
const _b1481cf0 = () => interopDefault(import('../pages/accounts/onboarding/reports.vue' /* webpackChunkName: "pages/accounts/onboarding/reports" */))
const _64cd93ae = () => interopDefault(import('../pages/global/features/new.vue' /* webpackChunkName: "pages/global/features/new" */))
const _4a2f589f = () => interopDefault(import('../pages/accounts/admin-dashboard/_dashboard_id.vue' /* webpackChunkName: "pages/accounts/admin-dashboard/_dashboard_id" */))
const _efcd5abe = () => interopDefault(import('../pages/global/charts/_chart_id.vue' /* webpackChunkName: "pages/global/charts/_chart_id" */))
const _f99102e4 = () => interopDefault(import('../pages/global/component-breakdown/_component_id.vue' /* webpackChunkName: "pages/global/component-breakdown/_component_id" */))
const _2eaa6888 = () => interopDefault(import('../pages/global/email-reports/_report_id.vue' /* webpackChunkName: "pages/global/email-reports/_report_id" */))
const _6ef9bb31 = () => interopDefault(import('../pages/global/features/_feature_id.vue' /* webpackChunkName: "pages/global/features/_feature_id" */))
const _5d885538 = () => interopDefault(import('../pages/global/form-builder/_form_id.vue' /* webpackChunkName: "pages/global/form-builder/_form_id" */))
const _00c96238 = () => interopDefault(import('../pages/global/queries/_query_id.vue' /* webpackChunkName: "pages/global/queries/_query_id" */))
const _8b7bf164 = () => interopDefault(import('../pages/global/themes/_theme_id/index.vue' /* webpackChunkName: "pages/global/themes/_theme_id/index" */))
const _e6071306 = () => interopDefault(import('../pages/global/themes/_theme_id/editor/_page_schema_id/index.vue' /* webpackChunkName: "pages/global/themes/_theme_id/editor/_page_schema_id/index" */))
const _1452bf48 = () => interopDefault(import('../pages/dashboard/_account_id/index.vue' /* webpackChunkName: "pages/dashboard/_account_id/index" */))
const _90b7bf70 = () => interopDefault(import('../pages/history/_account_id/index.vue' /* webpackChunkName: "pages/history/_account_id/index" */))
const _f32badca = () => interopDefault(import('../pages/home/_account_id/index.vue' /* webpackChunkName: "pages/home/_account_id/index" */))
const _235dfb0b = () => interopDefault(import('../pages/leads/_account_id/index.vue' /* webpackChunkName: "pages/leads/_account_id/index" */))
const _29e8ba76 = () => interopDefault(import('../pages/teammates/_account_id/index.vue' /* webpackChunkName: "pages/teammates/_account_id/index" */))
const _1b3aa79a = () => interopDefault(import('../pages/users/_user_id.vue' /* webpackChunkName: "pages/users/_user_id" */))
const _75399e22 = () => interopDefault(import('../pages/website-manager/_account_id/index.vue' /* webpackChunkName: "pages/website-manager/_account_id/index" */))
const _38d8a590 = () => interopDefault(import('../pages/dashboard/_account_id/calendar.vue' /* webpackChunkName: "pages/dashboard/_account_id/calendar" */))
const _265ff287 = () => interopDefault(import('../pages/dashboard/_account_id/email-reports/index.vue' /* webpackChunkName: "pages/dashboard/_account_id/email-reports/index" */))
const _3a49ce2e = () => interopDefault(import('../pages/dashboard/_account_id/view-dashboard/index.vue' /* webpackChunkName: "pages/dashboard/_account_id/view-dashboard/index" */))
const _66b24e56 = () => interopDefault(import('../pages/home/_account_id/about.vue' /* webpackChunkName: "pages/home/_account_id/about" */))
const _1b01f6a5 = () => interopDefault(import('../pages/storage-manager/_account_id/density.vue' /* webpackChunkName: "pages/storage-manager/_account_id/density" */))
const _07410a12 = () => interopDefault(import('../pages/storage-manager/_account_id/discounts/index.vue' /* webpackChunkName: "pages/storage-manager/_account_id/discounts/index" */))
const _2b434766 = () => interopDefault(import('../pages/storage-manager/_account_id/global-settings/index.vue' /* webpackChunkName: "pages/storage-manager/_account_id/global-settings/index" */))
const _32e1152f = () => interopDefault(import('../pages/storage-manager/_account_id/leases/index.vue' /* webpackChunkName: "pages/storage-manager/_account_id/leases/index" */))
const _01d5648a = () => interopDefault(import('../pages/storage-manager/_account_id/location-settings/index.vue' /* webpackChunkName: "pages/storage-manager/_account_id/location-settings/index" */))
const _2d7a8f1e = () => interopDefault(import('../pages/storage-manager/_account_id/locations/index.vue' /* webpackChunkName: "pages/storage-manager/_account_id/locations/index" */))
const _6879486f = () => interopDefault(import('../pages/storage-manager/_account_id/units/index.vue' /* webpackChunkName: "pages/storage-manager/_account_id/units/index" */))
const _75916390 = () => interopDefault(import('../pages/units-manager/_account_id/inventory/index.vue' /* webpackChunkName: "pages/units-manager/_account_id/inventory/index" */))
const _4ed2ada9 = () => interopDefault(import('../pages/units-manager/_account_id/view-units/index.vue' /* webpackChunkName: "pages/units-manager/_account_id/view-units/index" */))
const _8cc88144 = () => interopDefault(import('../pages/website-manager/_account_id/blog-posts/index.vue' /* webpackChunkName: "pages/website-manager/_account_id/blog-posts/index" */))
const _1ada6623 = () => interopDefault(import('../pages/website-manager/_account_id/editor/index.vue' /* webpackChunkName: "pages/website-manager/_account_id/editor/index" */))
const _2e6a3b93 = () => interopDefault(import('../pages/website-manager/_account_id/faqs/index.vue' /* webpackChunkName: "pages/website-manager/_account_id/faqs/index" */))
const _74239972 = () => interopDefault(import('../pages/website-manager/_account_id/gallery/index.vue' /* webpackChunkName: "pages/website-manager/_account_id/gallery/index" */))
const _24ad27a8 = () => interopDefault(import('../pages/website-manager/_account_id/global-styles/index.vue' /* webpackChunkName: "pages/website-manager/_account_id/global-styles/index" */))
const _beaef6ec = () => interopDefault(import('../pages/website-manager/_account_id/navigation/index.vue' /* webpackChunkName: "pages/website-manager/_account_id/navigation/index" */))
const _23c78870 = () => interopDefault(import('../pages/website-manager/_account_id/notifications/index.vue' /* webpackChunkName: "pages/website-manager/_account_id/notifications/index" */))
const _0f3bf9be = () => interopDefault(import('../pages/website-manager/_account_id/options/index.vue' /* webpackChunkName: "pages/website-manager/_account_id/options/index" */))
const _60b6617d = () => interopDefault(import('../pages/website-manager/_account_id/popups/index.vue' /* webpackChunkName: "pages/website-manager/_account_id/popups/index" */))
const _1067220a = () => interopDefault(import('../pages/website-manager/_account_id/reviews/index.vue' /* webpackChunkName: "pages/website-manager/_account_id/reviews/index" */))
const _6d95d73d = () => interopDefault(import('../pages/website-manager/_account_id/seo/index.vue' /* webpackChunkName: "pages/website-manager/_account_id/seo/index" */))
const _04f7b4ac = () => interopDefault(import('../pages/website-manager/_account_id/unit-cache.vue' /* webpackChunkName: "pages/website-manager/_account_id/unit-cache" */))
const _cc98d428 = () => interopDefault(import('../pages/storage-manager/_account_id/locations/new.vue' /* webpackChunkName: "pages/storage-manager/_account_id/locations/new" */))
const _2fc8c6ff = () => interopDefault(import('../pages/storage-manager/_account_id/locations/onboarding.vue' /* webpackChunkName: "pages/storage-manager/_account_id/locations/onboarding" */))
const _81f04992 = () => interopDefault(import('../pages/units-manager/_account_id/view-units/new.vue' /* webpackChunkName: "pages/units-manager/_account_id/view-units/new" */))
const _2c7f6f78 = () => interopDefault(import('../pages/website-manager/_account_id/editor/location-page.vue' /* webpackChunkName: "pages/website-manager/_account_id/editor/location-page" */))
const _8b004e4a = () => interopDefault(import('../pages/website-manager/_account_id/editor/new-page.vue' /* webpackChunkName: "pages/website-manager/_account_id/editor/new-page" */))
const _456735d4 = () => interopDefault(import('../pages/website-manager/_account_id/notifications/new.vue' /* webpackChunkName: "pages/website-manager/_account_id/notifications/new" */))
const _ffa317ea = () => interopDefault(import('../pages/website-manager/_account_id/popups/new.vue' /* webpackChunkName: "pages/website-manager/_account_id/popups/new" */))
const _0aa6e1d6 = () => interopDefault(import('../pages/website-manager/_account_id/editor/template/_page_schema_id/index.vue' /* webpackChunkName: "pages/website-manager/_account_id/editor/template/_page_schema_id/index" */))
const _ff5e2fc8 = () => interopDefault(import('../pages/dashboard/_account_id/view-dashboard/_dashboard_id.vue' /* webpackChunkName: "pages/dashboard/_account_id/view-dashboard/_dashboard_id" */))
const _50d6ecfc = () => interopDefault(import('../pages/leads/_account_id/view/_lead_id.vue' /* webpackChunkName: "pages/leads/_account_id/view/_lead_id" */))
const _542c080a = () => interopDefault(import('../pages/storage-manager/_account_id/discounts/_discount_id.vue' /* webpackChunkName: "pages/storage-manager/_account_id/discounts/_discount_id" */))
const _b36d40e6 = () => interopDefault(import('../pages/storage-manager/_account_id/locations/_location_id/index.vue' /* webpackChunkName: "pages/storage-manager/_account_id/locations/_location_id/index" */))
const _fbcd2326 = () => interopDefault(import('../pages/storage-manager/_account_id/units/_unit_id/index.vue' /* webpackChunkName: "pages/storage-manager/_account_id/units/_unit_id/index" */))
const _94eec4be = () => interopDefault(import('../pages/units-manager/_account_id/view-units/_product_id.vue' /* webpackChunkName: "pages/units-manager/_account_id/view-units/_product_id" */))
const _0add3c0e = () => interopDefault(import('../pages/website-manager/_account_id/editor/_page_id/index.vue' /* webpackChunkName: "pages/website-manager/_account_id/editor/_page_id/index" */))
const _84558c72 = () => interopDefault(import('../pages/website-manager/_account_id/notifications/_email_notification_id.vue' /* webpackChunkName: "pages/website-manager/_account_id/notifications/_email_notification_id" */))
const _4cdf5498 = () => interopDefault(import('../pages/website-manager/_account_id/popups/_popup_id.vue' /* webpackChunkName: "pages/website-manager/_account_id/popups/_popup_id" */))
const _019033e6 = () => interopDefault(import('../pages/website-manager/_account_id/reviews/_location_id.vue' /* webpackChunkName: "pages/website-manager/_account_id/reviews/_location_id" */))
const _5c9bc3c2 = () => interopDefault(import('../pages/storage-manager/_account_id/locations/_location_id/edit.vue' /* webpackChunkName: "pages/storage-manager/_account_id/locations/_location_id/edit" */))
const _7aaa3499 = () => interopDefault(import('../pages/website-manager/_account_id/editor/_page_id/view.vue' /* webpackChunkName: "pages/website-manager/_account_id/editor/_page_id/view" */))
const _490e8dc6 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

// TODO: remove in Nuxt 3
const emptyFn = () => {}
const originalPush = Router.prototype.push
Router.prototype.push = function push (location, onComplete = emptyFn, onAbort) {
  return originalPush.call(this, location, onComplete, onAbort)
}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: decodeURI('/'),
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/404",
    component: _bc869ba8,
    name: "404"
  }, {
    path: "/accounts",
    component: _7a3d17a5,
    name: "accounts"
  }, {
    path: "/chrome",
    component: _54dfb836,
    name: "chrome"
  }, {
    path: "/iframe",
    component: _01784360,
    name: "iframe"
  }, {
    path: "/login",
    component: _c84044c0,
    name: "login"
  }, {
    path: "/users",
    component: _c683fd02,
    name: "users"
  }, {
    path: "/accounts/admin-calendar",
    component: _019ad5b9,
    name: "accounts-admin-calendar"
  }, {
    path: "/accounts/admin-dashboard",
    component: _2707bc6c,
    name: "accounts-admin-dashboard"
  }, {
    path: "/accounts/onboarding",
    component: _05447f5b,
    name: "accounts-onboarding"
  }, {
    path: "/global/amenities",
    component: _ae2e99ac,
    name: "global-amenities"
  }, {
    path: "/global/changelog",
    component: _58c81767,
    name: "global-changelog"
  }, {
    path: "/global/charts",
    component: _a9b24f10,
    name: "global-charts"
  }, {
    path: "/global/client-value",
    component: _425dfa92,
    name: "global-client-value"
  }, {
    path: "/global/clubhouse",
    component: _acc77306,
    name: "global-clubhouse"
  }, {
    path: "/global/component-breakdown",
    component: _7dee3638,
    name: "global-component-breakdown"
  }, {
    path: "/global/email-reports",
    component: _c244043e,
    name: "global-email-reports"
  }, {
    path: "/global/features",
    component: _cbe7b740,
    name: "global-features"
  }, {
    path: "/global/form-builder",
    component: _2e95d175,
    name: "global-form-builder"
  }, {
    path: "/global/page-search",
    component: _b8e8300e,
    name: "global-page-search"
  }, {
    path: "/global/queries",
    component: _5fad2cb9,
    name: "global-queries"
  }, {
    path: "/global/themes",
    component: _1155e9ad,
    name: "global-themes"
  }, {
    path: "/login/confirm-sign-in",
    component: _434d8914,
    name: "login-confirm-sign-in"
  }, {
    path: "/login/forgot-password",
    component: _0c4209d3,
    name: "login-forgot-password"
  }, {
    path: "/login/index-copy",
    component: _8ca2c69c,
    name: "login-index-copy"
  }, {
    path: "/my-account/notification-settings",
    component: _105be684,
    name: "my-account-notification-settings"
  }, {
    path: "/my-account/security",
    component: _68ddda57,
    name: "my-account-security"
  }, {
    path: "/users/permissions",
    component: _44777f1e,
    name: "users-permissions"
  }, {
    path: "/accounts/admin-dashboard/admin-dashboard",
    component: _e3769ea0,
    name: "accounts-admin-dashboard-admin-dashboard"
  }, {
    path: "/accounts/onboarding/dashboard",
    component: _d420a0c6,
    name: "accounts-onboarding-dashboard"
  }, {
    path: "/accounts/onboarding/reports",
    component: _b1481cf0,
    name: "accounts-onboarding-reports"
  }, {
    path: "/global/features/new",
    component: _64cd93ae,
    name: "global-features-new"
  }, {
    path: "/accounts/admin-dashboard/:dashboard_id?",
    component: _4a2f589f,
    name: "accounts-admin-dashboard-dashboard_id"
  }, {
    path: "/global/charts/:chart_id",
    component: _efcd5abe,
    name: "global-charts-chart_id"
  }, {
    path: "/global/component-breakdown/:component_id?",
    component: _f99102e4,
    name: "global-component-breakdown-component_id"
  }, {
    path: "/global/email-reports/:report_id?",
    component: _2eaa6888,
    name: "global-email-reports-report_id"
  }, {
    path: "/global/features/:feature_id",
    component: _6ef9bb31,
    name: "global-features-feature_id"
  }, {
    path: "/global/form-builder/:form_id?",
    component: _5d885538,
    name: "global-form-builder-form_id"
  }, {
    path: "/global/queries/:query_id",
    component: _00c96238,
    name: "global-queries-query_id"
  }, {
    path: "/global/themes/:theme_id",
    component: _8b7bf164,
    name: "global-themes-theme_id"
  }, {
    path: "/global/themes/:theme_id/editor/:page_schema_id",
    component: _e6071306,
    name: "global-themes-theme_id-editor-page_schema_id"
  }, {
    path: "/dashboard/:account_id",
    component: _1452bf48,
    name: "dashboard-account_id"
  }, {
    path: "/history/:account_id",
    component: _90b7bf70,
    name: "history-account_id"
  }, {
    path: "/home/:account_id",
    component: _f32badca,
    name: "home-account_id"
  }, {
    path: "/leads/:account_id",
    component: _235dfb0b,
    name: "leads-account_id"
  }, {
    path: "/teammates/:account_id",
    component: _29e8ba76,
    name: "teammates-account_id"
  }, {
    path: "/users/:user_id",
    component: _1b3aa79a,
    name: "users-user_id"
  }, {
    path: "/website-manager/:account_id",
    component: _75399e22,
    name: "website-manager-account_id"
  }, {
    path: "/dashboard/:account_id?/calendar",
    component: _38d8a590,
    name: "dashboard-account_id-calendar"
  }, {
    path: "/dashboard/:account_id?/email-reports",
    component: _265ff287,
    name: "dashboard-account_id-email-reports"
  }, {
    path: "/dashboard/:account_id?/view-dashboard",
    component: _3a49ce2e,
    name: "dashboard-account_id-view-dashboard"
  }, {
    path: "/home/:account_id?/about",
    component: _66b24e56,
    name: "home-account_id-about"
  }, {
    path: "/storage-manager/:account_id?/density",
    component: _1b01f6a5,
    name: "storage-manager-account_id-density"
  }, {
    path: "/storage-manager/:account_id?/discounts",
    component: _07410a12,
    name: "storage-manager-account_id-discounts"
  }, {
    path: "/storage-manager/:account_id?/global-settings",
    component: _2b434766,
    name: "storage-manager-account_id-global-settings"
  }, {
    path: "/storage-manager/:account_id?/leases",
    component: _32e1152f,
    name: "storage-manager-account_id-leases"
  }, {
    path: "/storage-manager/:account_id?/location-settings",
    component: _01d5648a,
    name: "storage-manager-account_id-location-settings"
  }, {
    path: "/storage-manager/:account_id?/locations",
    component: _2d7a8f1e,
    name: "storage-manager-account_id-locations"
  }, {
    path: "/storage-manager/:account_id?/units",
    component: _6879486f,
    name: "storage-manager-account_id-units"
  }, {
    path: "/units-manager/:account_id?/inventory",
    component: _75916390,
    name: "units-manager-account_id-inventory"
  }, {
    path: "/units-manager/:account_id?/view-units",
    component: _4ed2ada9,
    name: "units-manager-account_id-view-units"
  }, {
    path: "/website-manager/:account_id?/blog-posts",
    component: _8cc88144,
    name: "website-manager-account_id-blog-posts"
  }, {
    path: "/website-manager/:account_id?/editor",
    component: _1ada6623,
    name: "website-manager-account_id-editor"
  }, {
    path: "/website-manager/:account_id?/faqs",
    component: _2e6a3b93,
    name: "website-manager-account_id-faqs"
  }, {
    path: "/website-manager/:account_id?/gallery",
    component: _74239972,
    name: "website-manager-account_id-gallery"
  }, {
    path: "/website-manager/:account_id?/global-styles",
    component: _24ad27a8,
    name: "website-manager-account_id-global-styles"
  }, {
    path: "/website-manager/:account_id?/navigation",
    component: _beaef6ec,
    name: "website-manager-account_id-navigation"
  }, {
    path: "/website-manager/:account_id?/notifications",
    component: _23c78870,
    name: "website-manager-account_id-notifications"
  }, {
    path: "/website-manager/:account_id?/options",
    component: _0f3bf9be,
    name: "website-manager-account_id-options"
  }, {
    path: "/website-manager/:account_id?/popups",
    component: _60b6617d,
    name: "website-manager-account_id-popups"
  }, {
    path: "/website-manager/:account_id?/reviews",
    component: _1067220a,
    name: "website-manager-account_id-reviews"
  }, {
    path: "/website-manager/:account_id?/seo",
    component: _6d95d73d,
    name: "website-manager-account_id-seo"
  }, {
    path: "/website-manager/:account_id?/unit-cache",
    component: _04f7b4ac,
    name: "website-manager-account_id-unit-cache"
  }, {
    path: "/storage-manager/:account_id?/locations/new",
    component: _cc98d428,
    name: "storage-manager-account_id-locations-new"
  }, {
    path: "/storage-manager/:account_id?/locations/onboarding",
    component: _2fc8c6ff,
    name: "storage-manager-account_id-locations-onboarding"
  }, {
    path: "/units-manager/:account_id?/view-units/new",
    component: _81f04992,
    name: "units-manager-account_id-view-units-new"
  }, {
    path: "/website-manager/:account_id?/editor/location-page",
    component: _2c7f6f78,
    name: "website-manager-account_id-editor-location-page"
  }, {
    path: "/website-manager/:account_id?/editor/new-page",
    component: _8b004e4a,
    name: "website-manager-account_id-editor-new-page"
  }, {
    path: "/website-manager/:account_id?/notifications/new",
    component: _456735d4,
    name: "website-manager-account_id-notifications-new"
  }, {
    path: "/website-manager/:account_id?/popups/new",
    component: _ffa317ea,
    name: "website-manager-account_id-popups-new"
  }, {
    path: "/website-manager/:account_id?/editor/template/:page_schema_id",
    component: _0aa6e1d6,
    name: "website-manager-account_id-editor-template-page_schema_id"
  }, {
    path: "/dashboard/:account_id?/view-dashboard/:dashboard_id?",
    component: _ff5e2fc8,
    name: "dashboard-account_id-view-dashboard-dashboard_id"
  }, {
    path: "/leads/:account_id?/view/:lead_id?",
    component: _50d6ecfc,
    name: "leads-account_id-view-lead_id"
  }, {
    path: "/storage-manager/:account_id?/discounts/:discount_id?",
    component: _542c080a,
    name: "storage-manager-account_id-discounts-discount_id"
  }, {
    path: "/storage-manager/:account_id?/locations/:location_id",
    component: _b36d40e6,
    name: "storage-manager-account_id-locations-location_id"
  }, {
    path: "/storage-manager/:account_id?/units/:unit_id",
    component: _fbcd2326,
    name: "storage-manager-account_id-units-unit_id"
  }, {
    path: "/units-manager/:account_id?/view-units/:product_id?",
    component: _94eec4be,
    name: "units-manager-account_id-view-units-product_id"
  }, {
    path: "/website-manager/:account_id?/editor/:page_id",
    component: _0add3c0e,
    name: "website-manager-account_id-editor-page_id"
  }, {
    path: "/website-manager/:account_id?/notifications/:email_notification_id",
    component: _84558c72,
    name: "website-manager-account_id-notifications-email_notification_id"
  }, {
    path: "/website-manager/:account_id?/popups/:popup_id",
    component: _4cdf5498,
    name: "website-manager-account_id-popups-popup_id"
  }, {
    path: "/website-manager/:account_id?/reviews/:location_id",
    component: _019033e6,
    name: "website-manager-account_id-reviews-location_id"
  }, {
    path: "/storage-manager/:account_id?/locations/:location_id?/edit",
    component: _5c9bc3c2,
    name: "storage-manager-account_id-locations-location_id-edit"
  }, {
    path: "/website-manager/:account_id?/editor/:page_id/view",
    component: _7aaa3499,
    name: "website-manager-account_id-editor-page_id-view"
  }, {
    path: "/",
    component: _490e8dc6,
    name: "index"
  }],

  parseQuery: function(queryString) {
      return require('qs').parse(queryString)
    },
  stringifyQuery: function(object) {
      var queryString = require('qs').stringify(object)
      return queryString ? '?' + queryString : ''
    },
  fallback: false
}

export function createRouter () {
  return new Router(routerOptions)
}
