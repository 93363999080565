import Vue from 'vue'

import { getMatchedComponentsInstances, getChildrenComponentInstancesUsingFetch, promisify, globalHandleError, urlJoin, sanitizeComponent } from './utils'
import NuxtError from '../layouts/error.vue'
import NuxtLoading from './components/nuxt-loading.vue'

import '../assets/sass/pug.scss'

import '../assets/sass/pug_dark.scss'

import '../assets/sass/aos.scss'

import '../node_modules/gridstack/dist/gridstack.min.css'

import '../node_modules/@fortawesome/fontawesome-free/js/all.min.js'

import '../node_modules/snazzy-info-window/dist/snazzy-info-window.min.css'

import '../node_modules/vue-multiselect/dist/vue-multiselect.min.css'

import '../node_modules/flatpickr/dist/themes/airbnb.css'

import '../node_modules/element-ui/lib/theme-chalk/index.css'

import _65a88512 from '../layouts/account.vue'
import _1eed1517 from '../layouts/adminEmailReport.vue'
import _6d665fcb from '../layouts/charts.vue'
import _6f6c098b from '../layouts/default.vue'
import _709d1512 from '../layouts/globalCalendar.vue'
import _f4764e64 from '../layouts/globalCharts.vue'
import _7ab02aad from '../layouts/kanban.vue'
import _f78de53c from '../layouts/leads-table.vue'
import _77a1ca61 from '../layouts/leads.vue'
import _77a66d33 from '../layouts/login.vue'
import _77deee76 from '../layouts/popup.vue'
import _00611712 from '../layouts/preview.vue'
import _77ef9692 from '../layouts/query.vue'
import _30539f98 from '../layouts/unitsManager.vue'
import _65908527 from '../layouts/website-editor-page-schema.vue'
import _24b43c55 from '../layouts/website-editor.vue'

const layouts = { "_account": sanitizeComponent(_65a88512),"_adminEmailReport": sanitizeComponent(_1eed1517),"_charts": sanitizeComponent(_6d665fcb),"_default": sanitizeComponent(_6f6c098b),"_globalCalendar": sanitizeComponent(_709d1512),"_globalCharts": sanitizeComponent(_f4764e64),"_kanban": sanitizeComponent(_7ab02aad),"_leads-table": sanitizeComponent(_f78de53c),"_leads": sanitizeComponent(_77a1ca61),"_login": sanitizeComponent(_77a66d33),"_popup": sanitizeComponent(_77deee76),"_preview": sanitizeComponent(_00611712),"_query": sanitizeComponent(_77ef9692),"_unitsManager": sanitizeComponent(_30539f98),"_website-editor-page-schema": sanitizeComponent(_65908527),"_website-editor": sanitizeComponent(_24b43c55) }

export default {
  render (h, props) {
    const loadingEl = h('NuxtLoading', { ref: 'loading' })

    const layoutEl = h(this.layout || 'nuxt')
    const templateEl = h('div', {
      domProps: {
        id: '__layout'
      },
      key: this.layoutName
    }, [layoutEl])

    const transitionEl = h('transition', {
      props: {
        name: 'layout',
        mode: 'out-in'
      },
      on: {
        beforeEnter (el) {
          // Ensure to trigger scroll event after calling scrollBehavior
          window.$nuxt.$nextTick(() => {
            window.$nuxt.$emit('triggerScroll')
          })
        }
      }
    }, [templateEl])

    return h('div', {
      domProps: {
        id: '__nuxt'
      }
    }, [
      loadingEl,

      transitionEl
    ])
  },

  data: () => ({
    isOnline: true,

    layout: null,
    layoutName: '',

    nbFetching: 0
    }),

  beforeCreate () {
    Vue.util.defineReactive(this, 'nuxt', this.$options.nuxt)
  },
  created () {
    // Add this.$nuxt in child instances
    Vue.prototype.$nuxt = this
    if (process.client) {
      // add to window so we can listen when ready
      window.$nuxt = this

      this.refreshOnlineStatus()
      // Setup the listeners
      window.addEventListener('online', this.refreshOnlineStatus)
      window.addEventListener('offline', this.refreshOnlineStatus)
    }
    // Add $nuxt.error()
    this.error = this.nuxt.error
    // Add $nuxt.context
    this.context = this.$options.context
  },

  async mounted () {
    this.$loading = this.$refs.loading
  },

  watch: {
    'nuxt.err': 'errorChanged'
  },

  computed: {
    isOffline () {
      return !this.isOnline
    },

    isFetching () {
      return this.nbFetching > 0
    },

    isPreview () {
      return Boolean(this.$options.previewData)
    },
  },

  methods: {
    refreshOnlineStatus () {
      if (process.client) {
        if (typeof window.navigator.onLine === 'undefined') {
          // If the browser doesn't support connection status reports
          // assume that we are online because most apps' only react
          // when they now that the connection has been interrupted
          this.isOnline = true
        } else {
          this.isOnline = window.navigator.onLine
        }
      }
    },

    async refresh () {
      const pages = getMatchedComponentsInstances(this.$route)

      if (!pages.length) {
        return
      }
      this.$loading.start()

      const promises = pages.map((page) => {
        const p = []

        // Old fetch
        if (page.$options.fetch && page.$options.fetch.length) {
          p.push(promisify(page.$options.fetch, this.context))
        }
        if (page.$fetch) {
          p.push(page.$fetch())
        } else {
          // Get all component instance to call $fetch
          for (const component of getChildrenComponentInstancesUsingFetch(page.$vnode.componentInstance)) {
            p.push(component.$fetch())
          }
        }

        if (page.$options.asyncData) {
          p.push(
            promisify(page.$options.asyncData, this.context)
              .then((newData) => {
                for (const key in newData) {
                  Vue.set(page.$data, key, newData[key])
                }
              })
          )
        }

        return Promise.all(p)
      })
      try {
        await Promise.all(promises)
      } catch (error) {
        this.$loading.fail(error)
        globalHandleError(error)
        this.error(error)
      }
      this.$loading.finish()
    },

    errorChanged () {
      if (this.nuxt.err && this.$loading) {
        if (this.$loading.fail) {
          this.$loading.fail(this.nuxt.err)
        }
        if (this.$loading.finish) {
          this.$loading.finish()
        }
      }
    },

    setLayout (layout) {
      if (!layout || !layouts['_' + layout]) {
        layout = 'default'
      }
      this.layoutName = layout
      this.layout = layouts['_' + layout]
      return this.layout
    },
    loadLayout (layout) {
      if (!layout || !layouts['_' + layout]) {
        layout = 'default'
      }
      return Promise.resolve(layouts['_' + layout])
    },
  },

  components: {
    NuxtLoading
  }
}
